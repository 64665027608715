<template>
  <div></div>
</template>
<script>
export default {
  data() {
    return {
      pageTitle: 'Assigned Class Info',
      schoolId: null,
    }
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'Assigned Classes',
        route: `/school/${this.schoolId}/assigned_classes`,
      },
    ])
  },
}
</script>
